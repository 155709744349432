.error-container {
  width: 100%;
  height: 100vh;
  background: #ee4b5e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .error-box {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 20px 30px -20px #000;
    overflow: hidden;
    margin-bottom: 15px;
  }

  .error-message {
    height: 46px;
    padding: 10px 46px;
  }

}

.error-image {
  width: 150px !important;
  height: 150px !important;
}


@font-face {
  font-family: Exo2;
  src: url(fonts/exo2/Exo2-Regular.otf) format('opentype');
}

@font-face {
  font-family: Exo2;
  src: url(fonts/exo2/Exo2-Bold.otf) format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: Exo2-Semi;
  src: url(fonts/exo2/Exo2-SemiBold.otf) format('opentype');
}

@import 'semantic.min.css';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #f6f6f6;
}

#root {
  height: 100%;
}

a {
  font-family: Exo2, sans-serif;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #00bfe7;
}

@keyframes fadeIn {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

.fade-in {
  animation: fadeIn .3s ease-in
}
